.react-chatbot-kit-chat-header {
    /*border-top-right-radius: 15px;*/
    /*border-top-left-radius: 15px;*/
    /*border: 1px solid #ACACAC;*/
    background-color: #000733;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #f1f1f1;
    padding: 15px 0 15px 25px;
    position: sticky;
}

.img-style {
    width: 50px;
    height: 50px;
    border: 1px solid #f1f1f1;
    border-radius: 50%;
    margin-right: 10px;
}
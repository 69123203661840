.react-chatbot-kit-user-chat-message-container {
  display: flex;
  margin: 15px 0 15px 70px;
  /*width: 275px;*/
  justify-content: flex-end;
}

/*.react-chatbot-kit-user-avatar-container {
  width: 0;
  height: 0;
  border-radius: 50%;
  margin-left: 12.5px;
  background-color: #3d4e8d;
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-chatbot-kit-user-avatar-icon {
  fill: #fff;
  width: 0;
  height: 0;
}*/

.react-chatbot-kit-user-chat-message {
  background-color: #ffffff;
  padding: 12px 15px 12px 12px;
  border-radius: 7px 0 0 7px;
  border: 1px solid #233E80;
  height: 50%;
  font-size: 0.9rem;
  color: #000000;
  font-weight: normal;
  position: relative;
  text-align: left;
}

.react-chatbot-kit-user-chat-message-arrow {
  display: flex;
  width: 4px;
  height: 100%;
  background-color: #233E80;
  position: absolute;
  right: 0;
  top: 0;
}

.react-chatbot-kit-chat-bot-message-container {
  display: flex;
  margin: 15px 70px 15px 0;
  /*width: 275px;*/
  justify-content: flex-start;
}

.react-chatbot-kit-chat-bot-avatar-container {
  /*width: 0;*/
  /*height: 0;*/
  /*border-radius: 50%;*/
  /*font-size: 1.2rem;*/
  /*margin-right: 0;*/
  /*background-color: #6A6E9B;*/
  /*display: flex;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  display: none;
}

.react-chatbot-kit-chat-bot-avatar-icon {
  fill: #494646;
  width: 0;
  height: 0;
}

.react-chatbot-kit-chat-bot-avatar-letter {
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.react-chatbot-kit-chat-bot-message {
  background-color: #ffffff;
  padding: 12px 12px 12px 15px ;
  border-radius: 0 7px 7px 0;
  border: 1px solid #25D9B8;
  height: 50%;
  font-size: 0.9rem;
  color: #000000;
  font-weight: normal;
  position: relative;
  text-align: left;
}

.react-chatbot-kit-chat-bot-message-arrow {
  display: flex;
  width: 4px;
  height: 100%;
  background-color: #25D9B8;
  position: absolute;
  left: 0;
  top: 0;
}

.react-chatbot-kit-chat-bot-loading-icon-container {
  height: 17px;
  width: 25px;
}

.chatbot-loader-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

#chatbot-loader #chatbot-loader-dot1 {
  animation: load 1s infinite;
}

#chatbot-loader #chatbot-loader-dot2 {
  animation: load 1s infinite;
  animation-delay: 0.2s;
}

#chatbot-loader #chatbot-loader-dot3 {
  animation: load 1s infinite;
  animation-delay: 0.4s;
}

@keyframes load {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.react-chatbot-kit-chat-container {
  position: relative;
  height: 100%;
}

.react-chatbot-kit-chat-inner-container {
  width: 100svw;
  max-width: 600px;
  /*height: 650px;*/
  height: 100svh;
  background-color: #BABEC7;
  /*background-image: url("/public/Voxxify_Graphic_Device_008-scaled.jpg");*/
  /*border-radius: 15px;*/
}

.react-chatbot-kit-chat-input-container {
  width: inherit;
  max-width: inherit;
  position: fixed;
  bottom: 0;
  /*width: 375px;*/
  display: flex;
}

.react-chatbot-kit-chat-message-container {
  padding: 0 15px 40px 15px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 80svh;
}

.react-chatbot-kit-chat-input-form {
  width: 100%;
  display: flex;
}

.react-chatbot-kit-chat-input-form input:focus {
  outline: none;
}

.react-chatbot-kit-chat-input {
  width: 100svw;
  max-width: 600px;
  padding: 26px;
  border: none;
  font-size: 0.85rem;
  border-top: 1px solid #ACACAC;
  /*border-bottom-left-radius: 15px;*/
  position: sticky;
  bottom: 0;
}

.react-chatbot-kit-chat-input::placeholder {
  color: #ACACAC;
}

.react-chatbot-kit-chat-btn-send {
  background-color: #ffffff;
  width: 100px;
  border: none;
  color: #ffffff;
  border-top: 1px solid #ACACAC;
  /*border-bottom-right-radius: 15px;*/
}

.react-chatbot-kit-chat-btn-send-icon {
  fill: #25D9B8;
  width: 15px;
  margin: 0 auto;
}

.react-chatbot-kit-error {
  background-color: #fff;
  border-radius: 3px;
  padding: 15px;
}

.react-chatbot-kit-error-container {
  width: 260px;
}

.react-chatbot-kit-error-header {
  font-size: 1.3rem;
  color: #1d1d1d;
  margin-bottom: 30px;
}

.react-chatbot-kit-error-docs {
  display: block;
  margin: 25px auto;
  color: rgb(56, 104, 139);
  padding: 8px;
  border: 1px solid rgb(40, 152, 236);
  width: 130px;
  text-align: center;
  text-decoration: none;
  font-size: 1rem;
}

